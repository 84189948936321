import { ApiClient } from "./api/client";
import { processResponse } from "./api/helpers";


export const downloadFileFromUrl = async (url: string|Promise<Blob>, downloadAs: string) => {
    let resultURL = await (typeof url=='string'? (await fetch(url as string)).blob():(url as Promise<Blob>))
    const href = window.URL.createObjectURL(resultURL);
    let anchor = document.createElement('a')
    anchor.setAttribute('href', href)
    anchor.setAttribute('download', downloadAs)
    anchor.style.display = 'none'
    document.body.appendChild(anchor)
    anchor.click()
    window.URL.revokeObjectURL(href);
}

export const downloadFileFromBackend=async(url:string,saveAs:string,params={})=>{
    let {data}=processResponse(await ApiClient.get(url,{params,responseType:'blob'}))
    data && downloadFileFromUrl(data as any,saveAs)
}