import { clone } from "Tools/object";
import axios, { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import SecureLS from "secure-ls";

function createClient() {
    const tokenPrefix = process.env.REACT_APP_ENVIRONMENT_PREFIX || ''
    const client = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    client.interceptors.request.use(request => {
        return request
    }, error => error);

    client.interceptors.response.use(success => success, async (error: AxiosError) => {
        let { response: { status = null, request } = {} } = error
        let storage = new SecureLS({ encodingType: 'aes' })
        // let retryCount=(()=>{try{return +storage.get('auth-refresh-retry-count')||0}catch{return 0}})()
        // Attempt to obtain another access token
        // assuming the acces token has expired.
        // (Note that the refresh api is not called anywhere else in the application)
        let refreshToken = storage.get(tokenPrefix + 'refresh')
        if (status === 401 && refreshToken) {
            let access: string_null_t, refresh: string_null_t
            // FIX TO ADDRES REQUEST ATTEMPTS
            // if(retryCount>=2){
            //     storage.remove('auth-refresh-retry-count')
            //     return Promise.reject(error)
            // }
            // storage.set('auth-refresh-retry-count',++retryCount);
            // END
            storage.remove(tokenPrefix + 'refresh');
            ({ status, data: { access, refresh } = {} as TokenPayload } = await client.post('token/refresh/', { refresh: refreshToken }));
            if (access && refresh) {
                storage.set(tokenPrefix + 'access', access)
                storage.set(tokenPrefix + 'refresh', refresh)
                storage.remove(tokenPrefix + 'auth-refresh-retry-count')
            }
        }
        else {
            return error
        }
    });

    return client
}




export const ApiClient = createClient()


export const setAuthorization = (accessToken?: string) => {
    ApiClient.defaults.headers['Authorization'] = accessToken && 'Bearer ' + accessToken || null
}
