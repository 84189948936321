import { FontSizes, Icon, Stack, Text } from "@fluentui/react"
import { CSSProperties, useCallback, useEffect, useState } from "react"


interface Props {
    onFileChanged: (file: File | null) => void
    file?: File | null
    style?: CSSProperties
    accept?: string
}
function dragOverHandler(ev: any) {
    ev.preventDefault();
}
export const FilePicker = ({ onFileChanged, style, accept, file: existingFile }: Props) => {

    let [file, setFile] = useState(existingFile)
    let dropHandler = useCallback((ev: any) => {
        ev.preventDefault();
        let file: File | null

        if (ev.dataTransfer.items) {
            let items = ev.dataTransfer.items || []
            if (items.length && items[0].kind === "file") {
                file = items[0].getAsFile();
                setFile(file)
            }

        } else {
            let files = ev.dataTransfer.files || []
            files.length && setFile(files[0])
        }
    }, [])
    useEffect(() => onFileChanged(file||null), [file])


    return <Stack onDrop={dropHandler} onDragOver={dragOverHandler} verticalAlign="space-between" horizontalAlign="center"
        style={{ position: 'relative', border: '3px double var(--themePrimary)',  borderSpacing: '.4rem', cursor: 'pointer', textAlign: 'center', padding: '1rem', ...style }}
    >
        <Text style={{ color: 'var(--themePrimary_alpha_5)',opacity:.4, fontSize: FontSizes.mediumPlus }}>Click here or drag file to {!file ? 'upload' : 'change'}</Text>

        <Icon iconName="DocumentSet" style={{ fontSize: FontSizes.superLarge, color: 'var(--themePrimary)' }} />
        <input onChange={(ev) => {
            let files = ev.target.files || []
            let file = files.length ? files[0] : null
            setFile(file)
        }} type="file" accept={accept} style={{ position: 'absolute', zIndex: 3, opacity: 0, width: '100%', height: '100%', top: 0, left: 0 }} />

        <Text style={{ visibility: file ? 'visible' : 'hidden', height: 39, position: 'relative', display: 'block', borderRadius: '.5rem', color: 'white', background: 'var(--themePrimary)', padding: '.3rem' }}>
            {file?.name}
        </Text>

    </Stack>
}